import { Component } from 'react';
import '../Game.scss';

/**
 * @component GameComponent
 * @desc The Game Component game page.
 * 
 * Please note - the number of images for this game needs to be set
 * in the getCarouselSteps function.
 */
class GameComponent extends Component {
	gameCode = "";

	constructor(props) {
		super(props);

		this.state = {
			deviceType: 0,
			demoStart: false,
			ui: "desktop",
		};
	}

	/**
	 * @method componentDidMount
	 * @desc Scrolls to the top of the page when it loads.
	 */
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	/**
	 * @method getDemoLink
	 * @desc Creates a link to the game in the demo environment.
	 * Each link gets a random demo user assigned to it.
	 */
	getDemoLink() {
		var url = "https://hubdemo.readyplaygaming.com/demo/?mode=fun&currency=USD&dontStop=true&homeURL=(back)&gameCode=";
		var link = url + this.gameCode;
		return link;
	}

	demoClick() {
		this.setState((state, props) => ({
			demoStart: true
		}));
	}

	componentWillUnmount() {
		window.removeEventListener("message", this.eventCB);
	}

	eventCB = event => {
		if (event?.data?.type && event.data.type === "gameLoaded") {
			this.deviceClick(this.state.deviceType)
			window.removeEventListener("message", this.eventCB);
		}
	}

	addEventListener() {
		window.removeEventListener("message", this.eventCB);
		window.addEventListener("message", this.eventCB);
	}

	deviceClick(type) {
		let ui = "desktop";
		switch (type) {
			case 1:
				ui = "portrait";
				document.getElementById('iframe')?.contentWindow.postMessage("RPG_ForceUI", "*");
				break;
			case 2:
				ui = "landscape";
				document.getElementById('iframe')?.contentWindow.postMessage("RPG_ForceUI", "*");
				break;
			default:
				ui = "desktop";
				document.getElementById('iframe')?.contentWindow.postMessage("RPG_StopForceUI", "*");
				break;

		}
		this.setState((state, props) => ({
			deviceType: type,
			ui: ui
		}));
		this.addEventListener();
	}

	render() {
		return;
	}
}

export default GameComponent;