import React, { Component } from 'react';
import NavbarComponent from './NavbarComponent';

class NavbarContainer extends Component {
    render() {
        return(
            <NavbarComponent
                selected={ this.props.selected }
            />
        );
    }
}

export default NavbarContainer;