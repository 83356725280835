import React, { Component } from 'react';
import GameOrientationChangerComponent from './GameOrientationChangerComponent';

class GameOrientationChangerContainer extends Component {
    render() {
        return(
            <GameOrientationChangerComponent 
                clickHandler={this.props.clickHandler}
            />
        );
    }
}

export default GameOrientationChangerContainer;