import React, { PureComponent } from 'react';
import './styles.scss';

class FooterComponent extends PureComponent {
    render() {
        return(
            (!process.env.REACT_APP_IS_IO) ? footerDefault : footerIo
        );
    }
}

var footerDefault = 
<div id='footer'>
<div>
    <div id="footerLogo"></div>
    <div id='footerText'>
        <div>Ready Play Gaming is operated by Ellipse Entertainment Limited, which is licensed and regulated by the&nbsp;<a href='https://www.gamblingcontrol.org/' target="_blank" rel="noopener noreferrer">Alderney Gambling Control Commission</a>&nbsp;under license # 145C2</div>
        <div>All  games are certified by&nbsp;<a href='https://itechlabs.com/' target="_blank" rel="noopener noreferrer">iTech Labs</a></div>
        <div>Ⓒ Ready Play Gaming 2023</div>
    </div>
</div>
<div id='footerIcons'>
    <div id='adultsOnly' className='logo'></div>
    <a href='https://itechlabs.com/' target="_blank" rel="noopener noreferrer" id='itech' className='logo'> </a>
    <a href='https://www.gamblingcontrol.org/' target="_blank" rel="noopener noreferrer" id='alderney' className='logo'> </a>
</div>
</div>

var footerIo =
<div id='footer'>
<div>
	<div id="footerLogo"></div>
	<div id='footerText'>
		<div>All  games are certified by&nbsp;<a href='https://itechlabs.com/' target="_blank" rel="noopener noreferrer">iTech Labs</a></div>
		<div>Ⓒ Ready Play Gaming 2023</div>
	</div>
</div>
<div id='footerIcons'>
    <div id='adultsOnly' className='logo'></div>
    <a href='https://itechlabs.com/' target="_blank" rel="noopener noreferrer" id='itech' className='logo'> </a>
</div>
</div>

export default FooterComponent;