import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../../../../components/Navbar';
import Footer from '../../../../components/Footer';
import GameOrientationChanger from '../../../../components/GameOrientationChanger';
import GameComponent from '../_GameComponent/GameComponent';

import background from './bg.jpg'
import reelBingo from '../../../../assets/featureIcons/reelBingo.webp';
import GameIcon from './icon.webp'
import TrophyIcon from '../../../../assets/VolatilityIcons/max_win.svg';
import VolatilityIcon from '../../../../assets/VolatilityIcons/volatility_3.svg';

import { ChevronLeft } from '@mui/icons-material';
import { Chip } from '@mui/material';

import '../Game.scss';

/**
 * @component JungleFeverV2
 * @desc The Jungle Fever game page.
 * 
 * Please note - the number of images for this game needs to be set
 * in the getCarouselSteps function.
 */
class JungleFeverV2 extends GameComponent {
	
	gameCode = "RPG_JungleFeverV2RB95";
	displayName = "Jungle Fever with Reel Bingo";

	/**
	 * @method getGameImages
	 * @desc Creates a list of game orientation images to be used
	 */
	getGameImages() {
		var gameImages = [];
		for (var i = 0; i < 3; i++) {
			gameImages.push(require('./' + i + '.webp'));
		}
		return gameImages;
	}

    render() {
        let gameImages = this.getGameImages();
        return (
            <div className="gamePage">
                <Navbar selected="games" />
                <Link className="backBtnLink" to="/games">
                    <Chip
                        icon={<ChevronLeft className='icon' />}
                        label="Back to games"
                        className='backBtn button'
                        clickable
                    />
                </Link>
                <div className="upperBlock" style={{ backgroundImage: `url(${background})` }}>
                    <h1 className="title">{this.displayName}</h1>
                    <div className="container">
                        {
                            this.state.demoStart ?
                                <iframe id='iframe' className={"iframe " + this.state.ui} src={this.getDemoLink()} title='iFrame Game' />
                                :
                                <>
                                    <img className="gameImage" src={gameImages[this.state.deviceType]} alt="Game" />
                                    <Chip
                                        label="Play demo"
                                        className='demoBtn button pulse'
                                        onClick={() => this.demoClick()}
                                    />
                                </>
                        }
                    </div>
                    <GameOrientationChanger clickHandler={this.deviceClick.bind(this)} />
                </div>
                <div className="lowerBlock">
                    <div className="iconAndStats">
                        <h1 className="title mobile">{this.displayName}</h1>
                        <img className="gameIcon" src={GameIcon} alt="GameIcon" />
                        <Chip
                            label="Play demo"
                            className='demoBtn button mobile'
                            component="a"
                            clickable
                            href={this.getDemoLink()}
                        />
						<div className='stats'>
							<div className='max-win stat'>
                                <img className='icon' src={TrophyIcon} alt='max win'/>
								<h3>Max win</h3>
								<div>3,525x</div>
							</div>
							<div className='volatility stat'>
                                <img className='icon' src={VolatilityIcon} alt='volatility'/>
								<h3>Volatility</h3>
								<div>Medium</div>
							</div>
						</div>
					</div>
					<div className="featuresAndInfo">
						<div className='features block'>
							<h2 className='title'>Features</h2>
							<table>
								<tbody>
                                    <tr><th><img className='icon' src={reelBingo} alt='reelBingo'/></th>
										<td>
                                            Spin to mark off positions on a bingo card with stars. Complete your card within 50 spins to win the jackpot.
										</td>
									</tr>
									<tr><th>Wild 2x Multiplier</th>
										<td>
                                            Wild wins are doubled (x2)
                                        </td>
									</tr>
									<tr><th>Free Spins</th>
										<td>
                                            Win 15 free spins.
                                            <br/>
                                            During the free spins wins are tripled (x3)
                                        </td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className='info block'>
							<h2 className='title'>Info</h2>
							<table>
								<tbody>
									<tr><th>RTP's</th><td>92%, 93.5%, 95%</td></tr>
									<tr><th>Win hit rate</th><td>3.10</td></tr>
									<tr><th>Min bet</th><td>$0.10 ($0.15 with Extra Game enabled)</td></tr>
									<tr><th>Lines</th><td>10</td></tr>
									<tr><th>Reels</th><td>3x5</td></tr>
									<tr><th>Languages</th><td>English-American (en-US)<br/>Spanish (es-ES)<br/>Portuguese-Brazilian (pt-BR)</td></tr>
								</tbody>
							</table>
						</div>        
					</div>
				</div>

				<img src={require('./0.webp')} className='hidden-loader' alt='fast-loader' />
				<img src={require('./1.webp')} className='hidden-loader' alt='fast-loader' />
				<img src={require('./2.webp')} className='hidden-loader' alt='fast-loader' />

				<Footer />
			</div>
		);
	}
}

export default JungleFeverV2;