import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../../../../components/Navbar';
import Footer from '../../../../components/Footer';
import GameOrientationChanger from '../../../../components/GameOrientationChanger';
import GameComponent from '../_GameComponent/GameComponent';

import background from './bg.jpg'
import GameIcon from './icon.webp'
import TrophyIcon from '../../../../assets/VolatilityIcons/max_win.svg';
import VolatilityIcon from '../../../../assets/VolatilityIcons/volatility_3.svg';

import { ChevronLeft } from '@mui/icons-material';
import { Chip } from '@mui/material';

import '../Game.scss';

/**
 * @component PocoLoco
 * @desc The Poco Loco game page.
 */
class PocoLoco extends GameComponent {
	
	gameCode = "RPG_PocoLocoHHCC95";
	displayName = "Poco Loco with Hot Hot Chili Climb";

	/**
	 * @method getGameImages
	 * @desc Creates a list of game orientation images to be used
	 */
	getGameImages() {
		var gameImages = [];
		for (var i = 0; i < 3; i++) {
			gameImages.push(require('./' + i + '.webp'));
		}
		return gameImages;
	}

    render() {
        let gameImages = this.getGameImages();
        return (
            <div className="gamePage">
                <Navbar selected="games" />
                <Link className="backBtnLink" to="/games">
                    <Chip
                        icon={<ChevronLeft className='icon' />}
                        label="Back to games"
                        className='backBtn button'
                        clickable
                    />
                </Link>
                <div className="upperBlock" style={{ backgroundImage: `url(${background})` }}>
                    <h1 className="title">{this.displayName}</h1>
                    <div className="container">
                        {
                            this.state.demoStart ?
                                <iframe id='iframe' className={"iframe " + this.state.ui} src={this.getDemoLink()} title='iFrame Game' />
                                :
                                <>
                                    <img className="gameImage" src={gameImages[this.state.deviceType]} alt="Game" />
                                    <Chip
                                        label="Play demo"
                                        className='demoBtn button pulse'
                                        onClick={() => this.demoClick()}
                                    />
                                </>
                        }
                    </div>
                    <GameOrientationChanger clickHandler={this.deviceClick.bind(this)} />
                </div>
                <div className="lowerBlock">
                    <div className="iconAndStats">
                        <h1 className="title mobile">{this.displayName}</h1>
                        <img className="gameIcon" src={GameIcon} alt="GameIcon" />
                        <Chip
                            label="Play demo"
                            className='demoBtn button mobile'
                            component="a"
                            clickable
                            href={this.getDemoLink()}
                        />
						<div className='stats'>
							<div className='max-win stat'>
                                <img className='icon' src={TrophyIcon} alt='max win' />
								<h3>Max win</h3>
                                <div>2 093x</div>
							</div>
							<div className='volatility stat'>
                                <img className='icon' src={VolatilityIcon} alt='volatility' />
								<h3>Volatility</h3>
								<div>Medium</div>
							</div>
						</div>
					</div>
					<div className="featuresAndInfo">
						<div className='features block'>
							<h2 className='title'>Features</h2>
							<table>
								<tbody>
                                    <tr><th>Hot Hot Chili Climb</th>
										<td>
                                            Red and green chilis will land on the reels.<br/>
                                            3 green chilis award the Mild Jackpot.<br />
                                            3 red chilis award the Hot Jackpot.<br />
                                            3 mixed chilis (2 red, 1 green or 2 green, 1 red) activate the Chili Climb feature.

										</td>
									</tr>
                                    <tr><th>Chili Climb Feature</th>
										<td>
                                            Click a chili on each row to progress upwards. <br/>
                                            Each row contains 1 bad chili, which ends the feature if selected. <br />
                                            There may be x2 multipliers within the stack of chilis. <br />
                                            Your current selection row will have a flaming border if there's a x2 multiplier on it. <br />
                                            If you reveal a x2 multiplier, you move up a level and your current row's prize is doubled. The rows above it will adjust accordingly.

										</td>
									</tr>
                                    <tr><th>Free Spins</th>
										<td>
											Triggered by 3 Scatters, 12 free spins, additional Wilds on reels 2-5, respin feature available, can be re-triggered.
										</td>
									</tr>
                                    <tr><th>Respin</th>
										<td>
											3 stacked Candy on reel 1 triggers 3 respins, Candy symbols locked for remaining spins, paid at the end.
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className='info block'>
							<h2 className='title'>Info</h2>
							<table>
								<tbody>
									<tr><th>RTP's</th><td>92%, 93%, 95%</td></tr>
									<tr><th>Win hit rate</th><td>3.7</td></tr>
									<tr><th>Min bet</th><td>$0.20 ($0.30 with Extra Game enabled)</td></tr>
									<tr><th>Lines</th><td>20</td></tr>
									<tr><th>Reels</th><td>3x5</td></tr>
									<tr><th>Languages</th><td>English-American (en-US)<br/>Spanish (es-ES)<br/>Portuguese-Brazilian (pt-BR)</td></tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<img src={require('./0.webp')} className='hidden-loader' alt='fast-loader' />
				<img src={require('./1.webp')} className='hidden-loader' alt='fast-loader' />
				<img src={require('./2.webp')} className='hidden-loader' alt='fast-loader' />

				<Footer />
			</div>
		);
	}
}

export default PocoLoco;