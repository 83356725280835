import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from '@mui/icons-material';
import './styles.scss';

/**
 * @component Navbar
 * @desc The navigation bar shown at the top of the site.
 * The component need 1 prop passed to it:
 * - selected:  The currently selected tab.
 * 
 * On desktop (>700px), the navbar is fixed to the top with all tabs shown.
 * On mobile (<700px), "accordian" is used with a menu button to open and close it.
 */
class NavbarComponent extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isShown: false
        };
    }

    /**
     * @method toggleMenu
     * @desc Shows or hides the mobile navbar.
     */
    toggleMenu() {
        this.setState((state, props) => ({
            isShown: !state.isShown
        }));
    }

    /**
     * @method getClasses
     * @desc Creates a list of classes for each tab in the navbar.
     * - show:      Added to the tab if the mobile navbar is shown.
     * - selected:  Added to the currently selected tab (highlight red).
     */
    getClasses(tabName) {
        var classes = "";
        
        if (this.props.selected === tabName) {
            classes += " selected";
        }

        return classes;
    }

    render() {
        return(
            <div id='navbar' className={ this.state.isShown ? "show" : "" }>
                <Link 
                    id="navbarLogo"
                    to='/'
                ></Link>
                <div id="menuBtn" onClick={ this.toggleMenu.bind(this) }>
                    <Menu />
                    <div id="menuBtnText">Menu</div>
                </div>
                <Link 
                    id='homeTab' 
                    className={ 'home tab ' + this.getClasses("home") } 
                    to='/'
                >
                    Home
                </Link>
                <Link 
                    id='gamesTab' 
                    className={ 'games tab ' + this.getClasses("games") }
                    to='/games'
                    onClick={() => {if (window.location.href.indexOf('games') !== -1 && window.location.href.indexOf('games/') === -1) document.location.reload()}}
                >
                    Games
                </Link>
                <Link 
                    id='aboutTab' 
                    className={ 'about tab ' + this.getClasses("about") } 
                    to='/about'
                >
                    About
                </Link>
                <Link 
                    id='contactTab' 
                    className={ 'contact tab ' + this.getClasses("contact") } 
                    to='/contact'
                >
                    Contact
                </Link>
                <a className='cp-btn tab' href='https://clientportal.readyplaygaming.com/' target='_blank' rel="noreferrer">Client Portal</a>
            </div>
        );
    }
}

export default NavbarComponent;