import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../../../../components/Navbar';
import Footer from '../../../../components/Footer';
import GameOrientationChanger from '../../../../components/GameOrientationChanger';
import GameComponent from '../_GameComponent/GameComponent';

import background from './bg.jpg'
import chasingChedder from '../../../../assets/featureIcons/chasingCheddar.webp';
import GameIcon from './icon.webp';
import TrophyIcon from '../../../../assets/VolatilityIcons/max_win.svg';
import VolatilityIcon from '../../../../assets/VolatilityIcons/volatility_4.svg';

import { ChevronLeft } from '@mui/icons-material';
import { Chip } from '@mui/material';

import '../Game.scss';

/**
 * @component ReadyYetiRespin
 * @desc The Ready Yeti Respin game page.
 * 
 * Please note - the number of images for this game needs to be set
 * in the getCarouselSteps function.
 */
class ReadyYetiRespin extends GameComponent {

	gameCode = "RPG_ReadyYetiRespinCC95";
	displayName = "Ready Yeti Respin";

	/**
	 * @method getGameImages
	 * @desc Creates a list of game orientation images to be used
	 */
	getGameImages() {
		let gameImages = [];
		for (let i = 0; i < 3; i++) {
			gameImages.push(require('./' + i + '.webp'));
		}
		return gameImages;
	}

	render() {
		let gameImages = this.getGameImages();
		return (
			<div className="gamePage">
				<Navbar selected="games" />
				<Link className="backBtnLink" to="/games">
					<Chip
						icon={<ChevronLeft className='icon' />}
						label="Back to games"
						className='backBtn button'
						clickable
					/>
				</Link>
				<div className="upperBlock" style={{ backgroundImage: `url(${background})` }}>
					<h1 className="title">{this.displayName}</h1>
					<div className="container">
						{
							this.state.demoStart ?
								<iframe id='iframe' className={"iframe " + this.state.ui} src={this.getDemoLink()} title='iFrame Game' />
								:
								<>
									<img className="gameImage" src={gameImages[this.state.deviceType]} alt="Game" />
									<Chip
										label="Play demo"
										className='demoBtn button pulse'
										onClick={() => this.demoClick()}
									/>
								</>
						}
					</div>
					<GameOrientationChanger clickHandler={this.deviceClick.bind(this)} />
				</div>
				<div className="lowerBlock">
					<div className="iconAndStats">
						<h1 className="title mobile">{this.displayName}</h1>
						<img className="gameIcon" src={GameIcon} alt="GameIcon" />
						<Chip
							label="Play demo"
							className='demoBtn button mobile'
							component="a"
							clickable
							href={this.getDemoLink()}
						/>
						<div className='stats'>
							<div className='max-win stat'>
								<img className='icon' src={TrophyIcon} alt='max win' />
								<h3 className='title'>Max win</h3>
								<div className='value'>689x</div>
							</div>
							<div className='volatility stat'>
								<img className='icon' src={VolatilityIcon} alt='volatility' />
								<h3 className='title'>Volatility</h3>
								<div className='value'>Medium-High</div>
							</div>
						</div>
					</div>
					<div className="featuresAndInfo">
						<div className='features block'>
							<h2 className='title'>Features</h2>
							<table>
								<tbody>
									<tr><th><img className='icon' src={chasingChedder} alt='CC' /></th>
										<td>
											Spin and collect cheese on a surrounding board game. 3 different board games to choose from.
											<br />
											Complete the task of collecting 20 pieces of cheese within 50 spins to win the jackpot.
										</td>
									</tr>
									<tr><th>Avalanche Free Spins</th>
										<td>
											Triggered by 3 Scatters, choose your free spins:
											<br />
											<div className="centered">
												20 free spins + 30 Wilds added
												<br />
												or
												<br />
												12 free spins + 60 Wilds added
												<br />
												or
												<br />
												8 free spins + 100 Wilds added
											</div>
										</td>
									</tr>
									<tr><th>Yeti Respin</th>
										<td>
											3 stacked Yeti on reel 1 triggers 3 respins, Yeti symbols locked for remaining spins, paid at the end.
										</td>
									</tr>
									<tr><th>Scatter Wheel Respin</th>
										<td>
											Spin a wheel to reveal the chosen scatter symbol.
											<br />
											Reels spin and any scatter appearing are held. Spins end when 3 spins have resulted in no additional scatter landing. The final screen is paid as scattered.
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className='info block'>
							<h2 className='title'>Info</h2>
							<table>
								<tbody>
									<tr><th>RTP's</th><td>92%, 93%, 95%</td></tr>
									<tr><th>Win hit rate</th><td>3.6</td></tr>
									<tr><th>Min bet</th><td>$0.20 ($0.30 with Extra Game enabled)</td></tr>
									<tr><th>Lines</th><td>20</td></tr>
									<tr><th>Reels</th><td>3x5</td></tr>
									<tr><th>Languages</th><td>English-American (en-US)<br />Spanish (es-ES)<br />Portuguese-Brazilian (pt-BR)</td></tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<img src={require('./0.webp')} className='hidden-loader' alt='fast-loader' />
				<img src={require('./1.webp')} className='hidden-loader' alt='fast-loader' />
				<img src={require('./2.webp')} className='hidden-loader' alt='fast-loader' />

				<Footer />
			</div>
		);
	}
}

export default ReadyYetiRespin;