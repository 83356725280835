import React, { PureComponent } from 'react';
import './styles.scss';
import desktop from './desk.svg';
import portrait from './port.svg';
import landscape from './land.svg';
import desktopSelect from './desk_select.svg';
import portraitSelect from './port_select.svg';
import landscapeSelect from './land_select.svg';

class GameOrientationChangerComponent extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            desktopSelected: true,
            portraitSelected: false,
            landscapeSelected: false
        };
    }

    handleDesktopClick = () => {
        this.setState(prevState => ({
            desktopSelected: true,
            portraitSelected: false,
            landscapeSelected: false
        }));
        this.props.clickHandler(0);
    }

    handlePortraitClick = () => {
        this.setState(prevState => ({
            desktopSelected: false,
            portraitSelected: true,
            landscapeSelected: false
        }));
        this.props.clickHandler(1);
    }

    handleLandscapeClick = () => {
        this.setState(prevState => ({
            desktopSelected: false,
            portraitSelected: false,
            landscapeSelected: true
        }));
        this.props.clickHandler(2);
    }

    render() {
        const { desktopSelected, portraitSelected, landscapeSelected } = this.state;

        return(
            <div id='gameOrientationChanger'>
                <img 
                    id="desktop" 
                    src={desktopSelected ? desktopSelect : desktop} 
                    alt="desktop icon" 
                    onClick={this.handleDesktopClick}
                />
                <img 
                    id="portrait" 
                    src={portraitSelected ? portraitSelect : portrait} 
                    alt="portrait icon" 
                    onClick={this.handlePortraitClick}
                />
                <img 
                    id="landscape" 
                    src={landscapeSelected ? landscapeSelect : landscape} 
                    alt="landscape icon" 
                    onClick={this.handleLandscapeClick}
                />
            </div>
        );
    }
}

export default GameOrientationChangerComponent;